
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react';
import { InferGetStaticPropsType } from 'next';
import useTranslation from 'next-translate/useTranslation'
import Typography from '@mui/material/Typography';
import OmniLink from '@/components/ui/OmniLink';
import SiteLayout from '@/components/layout/site';
import { getBaseStaticProps } from '@/utils/slug';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import { PageType } from '@/utils/router';

const StyledTitle = styled(Typography)({
  fontSize: "51px",
  fontWeight: "800",
  letterSpacing: "0",
  lineHeight: "54px",
  marginBottom: "12px",
});

const StyledSubtitle = styled(Typography)({
  fontSize: "34px",
  fontWeight: "700",
  letterSpacing: "-0.23",
  lineHeight: "36px",
  margin: "0 0 30px",
});

const Error404Page = ({ data }: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { t } = useTranslation('common');

	return (<SiteLayout pageType={PageType.NOT_FOUND} data={data}>
    <Container fixed>
      <Grid container>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={1} lg={2} />
        <Grid item xs={12} md={10} lg={8}>
          <Box sx={{mt: '80px', mb: '120px'}}>
            <StyledTitle>
              {t('404-error-title', {}, {default: '404'})}
            </StyledTitle>
            <StyledSubtitle>
              {t('404-error-subtitle', {}, {default: 'Sorry, no result was found'})}
            </StyledSubtitle>
            <OmniLink href="/">
              {t('back-to-homepage', {}, {default:'Back to Homepage'})}
            </OmniLink>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </SiteLayout>);
}

export default Error404Page;

 const _getStaticProps = getBaseStaticProps;


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  